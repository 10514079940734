@use "global.module.scss" as *;

.loader {
    width: 60px;
    height: 25px;
    border: 2px solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: grid;
    animation: l2 2s infinite linear;
    color: $secondaryColor;
    margin: 2rem 1rem;
}

.loader:before,
.loader:after {
    content: "";
    grid-area: 1/1;
    border: inherit;
    border-radius: 50%;
    animation: inherit;
    animation-duration: 3s;
}

.loader:after {
    --s: -1;
}

@keyframes l2 {
    100% {
        transform: rotate(calc(var(--s, 1)*1turn))
    }
}