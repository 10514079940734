@use "global.module.scss" as *;

.container {
    color: $primaryColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.button {
    margin: 1rem 0;
    padding: 0.5rem;
    max-width: 200px;
    min-width: 200px;
    background-color: $primaryColor;
    color: black;
    border: 0 solid black;
    border-radius: 1rem;
}

.response {
    color: $primaryColor;
    margin-bottom: 1rem;
}

.input {
    margin: 1rem 0;
    padding: 0.5rem;
    max-width: 200px;
    min-width: 200px;
    border: 1px solid $primaryColor;
    border-radius: 1rem;
}