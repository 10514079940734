@use "global.module.scss" as *;

.conatiner {
    color: $primaryColor;
    display: flex;
    flex-direction: row;
    flex: 1 0;
    margin: 0 auto;
    font-size: .9rem;

    @media  (min-width: 1080px) {
        max-width: 1080px;
    }

    @media (max-width: 800px) {
        max-width: 100%;
        font-size: .66rem;
    }
}