@use "global.module.scss" as *;

.layout {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.page {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
}

.main {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}