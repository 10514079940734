import React from "react"
import { useState, useEffect, useCallback } from "react"
import { Outlet } from "react-router-dom"
import * as style from "../styles/Layout.module.scss"
import Header from "../hooks/Header"
import Nav from "./Nav"
import Footer from "../hooks/Footer"

export default function Layout() {
    return (
        <div className={style.layout}>
            <div className={style.main} >
                <Header />
                <div className={style.page}>
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    )
}
