import React, { useEffect, useRef, useState } from "react"
import * as style from "../styles/Dialog.module.scss"
import LoadingSpinner from "./LoadingSpinner"
import TextBubble, { IMessage } from "../hooks/TextBubble"
import { 
    faTrash,
    faEdit
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"

interface IEditThreadDictionary {
    id: number
    messages: IMessage[][]
}

export default function Dialog() {
    const current_date = new Date()
    const [systemMessage, setSystemMessage] = useState("Today is " + `${current_date.getFullYear()}-${String(current_date.getMonth() + 1).padStart(2, '0')}-${String(current_date.getDate()).padStart(2, '0')}` + ". You are LLaMA-3, a helpful AI assistant.")
    const [messages, setMessages] = useState([{ "role": "system", "content": systemMessage, "index": 0 }])
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(false)
    const conversationRef = useRef<HTMLDivElement | null>(null)
    const [editThreadDictionary, setEditThreadDictionary] = useState({} as IEditThreadDictionary)
    const [isEditing, setIsEditing] = useState(false)
    const [editingIndex, setEditingIndex] = useState(-1)
    const [shiftPressed, setShiftPressed] = useState(false)

    const bubbleContainerClass = (message: any) => {
        return cn(
            style.messageContainer, 
            (message.role === "user") ? style.user : style.assistant
        )
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        console.log("default useeffect messages:", messages)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [loading])

    useEffect(() => {
        if (!isEditing) return
        getMessagesResponse(editThreadDictionary[editingIndex][editThreadDictionary[editingIndex].length - 1])
        setIsEditing(false)
    }, [editThreadDictionary])

    function handleKeyDown(e) {
        if (e.key === 'Shift') {
            setShiftPressed(true)
        }
        if (e.key === 'Enter' && shiftPressed) return
        // send
        if (e.key === 'Enter') {
            e.preventDefault()
            sendMessage();
        }
    }

    function handleKeyUp(e) {
        if (e.key === 'Shift') {
            setShiftPressed(false)
        }
    }

    return (
        <div className={style.container}>
            <div className={style.inputContainer}>
                <textarea value={systemMessage} onChange={(e) => setSystemMessage(e.target.value)} />
                <button onClick={clearMessages}>Set</button>
            </div>
            <div className={style.conversation} ref={conversationRef}>
                {messages.map((message: IMessage, index: number) => {
                    return (
                        <div key={index} className={bubbleContainerClass(message)}>
                            <TextBubble index={message.index} role={message.role} content={message.content} />
                            {
                                message.role === "user" &&
                                <div className={style.messageEditContainer}>
                                    <button className={style.editMessageIcon} onClick={focusOnTextInput}>
                                        <FontAwesomeIcon icon={faEdit} color="black" />
                                    </button>
                                </div>
                            }
                        </div>
                    )
                })}
                {loading && <LoadingSpinner />}
            </div>
            <div className={style.inputContainer}>
                <button onClick={clearMessages}>
                    <FontAwesomeIcon icon={faTrash} color="black" />
                </button>
                <textarea id="send-box" value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    )

    function sendMessage() {
        if (loading) return
        if (isEditing) {
            addToEditThreadDictionary()
            return
        }
        setInput("")
        setLoading(true)
        messages.push({ "role": "user", "content": input, "index": messages.length })
        getMessagesResponse(messages)
        scrollToBottom()
    }

    function getMessagesResponse(messages: IMessage[]) {
        const result = fetch(`${process.env.BACKEND_URL}/hermes`, {
            method: 'POST',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ messages }),
            mode: 'cors'
        });
        result.then(async (response) => {
            setLoading(false)
            await streamToString(response.body)
        }).catch((error) => {
            console.log(error)
        });
    }

    async function streamToString(body: ReadableStream) {
        let newMessage = ""
        let firstToken = true;
        const reader: ReadableStreamDefaultReader<string> = body?.pipeThrough(new TextDecoderStream()).getReader()
        while (reader) {
            let stream = await reader.read()
            if (stream.done) break
            const chunks = stream.value
                .split("")
                // .split("data: ")
                .map((c: string) => {
                    if (firstToken) {
                        c = c.trim()
                        firstToken = false;
                    }
                    newMessage += c
                    setMessages([...messages, { "role": "assistant", "content": newMessage, "index": messages.length }])
                    handleResize()
                })
        }
    }

    function clearMessages() {
        setMessages([{ "role": "system", "content": systemMessage, "index": 0}]);
        setLoading(false)
    }

    function scrollToBottom(scrollLeft?: number, scrollTop?: number) {
        conversationRef.current.scrollTo({
            top: scrollTop || conversationRef.current.scrollHeight,
        })
    }

    function handleResize() {
        const header = document.querySelector('header')
        const footer = document.querySelector('footer')
        const inputContainer = document.querySelector(`.${style.inputContainer}`) as HTMLElement
        if (header && conversationRef.current) {
            const headerHeight = header.offsetHeight + footer.offsetHeight + inputContainer.offsetHeight * 2
            const windowHeight = window.innerHeight
            conversationRef.current.style.maxHeight = `${windowHeight - headerHeight}px`
        }
    }

    function focusOnTextInput(e: React.MouseEvent) {
        const input = document.querySelector('textarea[id="send-box"]') as HTMLInputElement
        const conversationDiv = e.currentTarget.parentElement.parentElement.parentElement as HTMLDivElement
        const thisText = e.currentTarget.parentElement.parentElement as HTMLDivElement
        for (let i = 0; i < conversationDiv.children.length; i++) {
            const textBubble = conversationDiv.children[i] as HTMLDivElement
            if (textBubble === thisText) {
                console.log("editingIndex:", i)
                setEditingIndex(i)
                setInput(messages[i].content)
                break
            }
        }
        setIsEditing(true)
        input.focus()
    }

    // takes a copy of the conversation up the message to edit, adds a new message, adds this to the editThreadDictionary
    function addToEditThreadDictionary() {
        const editThread = []
        console.log("editingIndex:", editingIndex)
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].index === editingIndex) break
            editThread.push(messages[i])
        }
        const newMessage = {
            index: editThread.length,
            role: "user",
            content: input
        }
        editThread.push(newMessage)

        const newEditDictionary = { ...editThreadDictionary }

        // if editingIndex is not in the dictionary, create new array with editThread in it
        // if it is, push the new thread to the messages array at editingIndex
        if (editThreadDictionary[editingIndex] === undefined) {
            const threads = []
            threads.push(editThread)
            newEditDictionary[editingIndex] = threads
        } else {
            newEditDictionary[editingIndex].push(editThread)
        }

        setMessages(newEditDictionary[editingIndex][newEditDictionary[editingIndex].length - 1])
        setEditThreadDictionary(newEditDictionary)
        setInput("")
    }
}
