import { Routes, Route, Link, BrowserRouter } from 'react-router-dom'
import React from 'react'
import * as style from '../styles/App.module.scss'
import Layout from './Layout'
import Riddle from '../pages/Riddle'
import Conversation from '../pages/Conversation'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Conversation />} />
                    <Route path="riddle" element={<Riddle />} />
                    {/* <Route path="*" element={<NoPage />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}