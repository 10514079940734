@use "global.module.scss" as *;

.shared {
    // position: relative; /* Setup a relative container for our psuedo elements */
    // max-width: 900px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .9rem;
    line-height: 1.2rem;
    word-wrap: break-word; /* Make sure the text wraps to multiple lines if long */
    border-radius: 6px;

    @media (max-width: 800px) {
        line-height: .9rem;
    }

    // background: linear-gradient(145deg, $primaryColor, $secondaryColor);

    // &:before {
    //     width: 20px;
    //     z-index: -2;
    // }

    // &:after {
    //     width: 26px;
    //     background-color: black;
    //     z-index: -1;
    //     /* All tails have the same bg cutout */
    // }

    // &:before,
    // &:after {
    //     position: absolute;
    //     bottom: 0;
    //     height: 25px; /* height of our bubble "tail" - should match the border-radius above */
    //     content: '';
    // }
}

.sent {
    align-self: flex-end;
    color: white;
    background-color: rgba($color: $primaryColor, $alpha: .2);

    // &:before {
    //     right: -7px;
    //     background-color: $primaryColor;
    //     border-bottom-left-radius: 16px 14px;
    // }

    // &:after {
    //     right: -26px;
    //     border-bottom-left-radius: 10px;
    // }
}

.received {
    align-self: flex-start;
    color: white;
    background-color: rgba($color: $secondaryColor, $alpha: .2);

    // &:before {
    //     left: -7px;
    //     background-color: $secondaryColor;
    //     border-bottom-right-radius: 16px 14px;
    // }

    // &:after {
    //     left: -26px;
    //     border-bottom-right-radius: 10px;
    // }
}