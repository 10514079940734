@use "global.module.scss" as *;

.header {
    font-family: ultra, Arial, Helvetica, sans-serif;
    padding: 1rem 0;
    font-size: 39px;
    color: $primaryColor;
    text-align: center;

    @media (max-width: 425px) {
        font-size: 36px;
    }
}