import React from "react"
import * as style from "../styles/Footer.module.scss"

function Footer() {
    return (
        <footer className={style.footer}>
            MCopp JMar @ 2024
        </footer>
    )
}
export default Footer