@use "global.module.scss" as *;

.container {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    width: 100%;
    max-width: 100%;
}

.conversation {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1 0;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.2rem;
}

.user {
    align-self: flex-end;
}

.assistant {
    align-self: flex-start;
}

.editMessageIcon {
    cursor: pointer;
    background-color: transparent;
    border: none;
}

// pill shape flex row
.messageEditContainer {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    padding: .12rem;
    margin: .3rem 0 0 1.2rem;
    background-color: $primaryColor;
    border-radius: 1rem;
    border: 1px solid black;
    width: min-content;
    z-index: 1;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    textarea {
        display: flex;
        flex: 1;
        padding: .6rem;
        height: 2rem;
        border-radius: .6rem;
        background-color: rgb(255, 211, 179);
        border: none;
        font-size: .9rem;

        @media (max-width: 800px) {
            font-size: .66rem;
        }

        &:focus {
            outline: none;
        }
    }

    button {
        margin: 0 .3rem;
        padding: 0 .6rem;
        height: 2rem;
        border: none;
        border-radius: .6rem;
        background-color: $primaryColor;
        color: black;
        font-weight: bold;
        cursor: pointer;
    }
}