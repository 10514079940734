import React, { useEffect } from "react"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import cn from "classnames"
import * as style from "../styles/TextBubble.module.scss"

export interface IMessage {
    role: string
    content: string
    index: number
}

// an app that prompts gpt for a riddle
export default function TextBubble(message: IMessage) {
    return (
        <>
            {
                message.role == "user" &&
                <div key={message.index} className={cn(style.shared, style.sent)}>
                    {/* {handleTrippleBacktick(message.content)} */}
                    {message.content}
                </div>
            }
            {
                message.role != "user" &&
                <div key={message.index} className={cn(style.shared, style.received)}>
                    {handleTrippleBacktick(message.content)} 
                </div>
            }
        </>
    )
}

function handleTrippleBacktick(message: string) {
    const trippleBackticks = message.match(/```/g)
    if (!trippleBackticks) {
        return <div>{message}</div>
    }
    if (trippleBackticks.length === 1) {
        return <div>{message}</div>
    }

    const trippleBacktickIndex = message.indexOf("```")
    const language = message.substring(trippleBacktickIndex + 3, message.indexOf("\n", trippleBacktickIndex + 3))
    const messageArray = message.split("```")
    const divs = messageArray.map((content, index) => {
        if (index % 2 === 0) {
            return <div key={index}>{content}</div>
        }
        return (
            <SyntaxHighlighter key={index} language={language} style={dark}>
                {content}
            </SyntaxHighlighter>
        )
    })
    return divs
}