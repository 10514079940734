import React, { useEffect } from "react"
import * as style from "../styles/Conversation.module.scss"
import Dialog from "../hooks/Dialog"

// an app that prompts gpt for a riddle
export default function Conversation() {
  return (
    <div className={style.conatiner}>
      <Dialog />
    </div>
  )
}
