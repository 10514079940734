import React, { useEffect } from "react"
import * as style from "../styles/Riddle.module.scss"

// an app that prompts gpt for a riddle
export default function Riddle() {
  const [inputWord, setInputWord] = React.useState('')
  const [word, setWord] = React.useState('')
  const [answer, setAnswer] = React.useState('')
  const [riddle, setRiddle] = React.useState('')
  const [hint, setHint] = React.useState('')
  const [showAnswer, setShowAnswer] = React.useState(false)

  async function fetchRiddle() {
    const result = fetch(`${process.env.BACKEND_URL}/riddle?word=${inputWord}`, { mode: 'cors' });
    result.then(async (response) => {
      const json = await response.json();
      setRiddle(json.riddle);
      setAnswer(json.answer);
    }).catch((error) => {
      console.log(error);
    });
  }

  async function fetchHint() {
    const result = fetch(`${process.env.BACKEND_URL}/hint?riddle=${riddle}`, { mode: 'cors' });
    result.then(async (response) => {
      const json = await response.json();
      setHint(json.hint);
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className={style.container}>
      <input className={style.input} type="text" value={inputWord} onChange={(e) => setInputWord(e.target.value)} />
      <button className={style.button} onClick={fetchRiddle}>Create Riddle!</button>
      {riddle && <button className={style.button} onClick={fetchHint}>Get Hint!</button>}
      <button className={style.button} onClick={() => setShowAnswer(!showAnswer)}>Show Answer</button>
      <div className={style.response}>
        riddle: {riddle}
      </div>
      <div className={style.response}>
        hint: {hint}
      </div>
      <div className={style.response}>
        answer: {answer && showAnswer ? answer : '???'}
      </div>
    </div>
  )
}
